
import { Vue, Component } from "vue-property-decorator";
import { mapMutations } from "vuex";
import {
    ResponseDataChart as responseChart,
    Chart,
    ChartError,
    FilterFormInterface
} from "@/interfaces";
import * as types from "@/store/types";
import { sleep } from "@/utils";
import { TOKEN } from "@/services/auth/auth-service";
@Component({
    components: {
        CardWidget: () => import("@/components/layout/CardWidget.vue"),
        CardStats: () => import("@/components/layout/CardStats.vue"),
        CardProgress: () => import("@/components/layout/CardProgress.vue"),
        PlotlyChart: () => import("@/components/charts/PlotlyChart.vue"),
        TabItem: () => import("@/components/layout/TabItem.vue"),
        FilterForm: () =>
            import("@/pages/components/forms/FilterFormStandardV2.vue")
    },
    methods: {
        ...mapMutations({
            setProgressBar: types.MUTATE_APP_PROGRESSBAR,
            setInfoMessage: types.MUTATE_APP_INFO_MESSAGE
        }),
        getColor(fpPeriod) {
            fpPeriod = Number(fpPeriod.replace(",", "."));
            if (fpPeriod < 0.95 && fpPeriod > 0.81) return "#e3bb07";
            else if (fpPeriod <= 0.81) return "orange";
            else if (fpPeriod > 1.02) return "red";
            else return "white";
        }
    }
})
export default class Resume extends Vue {
    setProgressBar!: (state: boolean) => void;
    setInfoMessage!: (state: { shown: boolean; text: string | null }) => void;
    filterFormPanelShow: boolean | null = false;
    filterForm: FilterFormInterface = {
        company: "",
        client: "",
        installation: "",
        modbus: "",
        valid: true,
        box: "",
        analyzer: "",
        element: "",
        periodType: "Ultimos 30 dias",
        start: undefined,
        end: undefined,
        window: "15m",
        aggregation: "last",
        comparative: false
    };
    start = "";
    end = "";
    start1 = "";
    start2 = "";
    end1 = "";
    end2 = "";
    first = false;
    showFilter = true;
    energy1Chart: Chart = { data: [] };
    period1Chart: Chart = { data: [] };
    element1Chart: Chart = { data: [{ values: [] }] };
    energy2Chart: Chart = { data: [] };
    period2Chart: Chart = { data: [] };
    element2Chart: Chart = { data: [{ values: [] }] };
    columnsRTot = [
        { text: "Trafo", sortable: true, value: "trafo" },
        {
            text: "Periodo 1-5",
            sortable: true,
            value: "per15",
            align: "right"
        },
        { text: "Multa", sortable: true, value: "mul1", align: "right" },
        { text: "Periodo 6", sortable: true, value: "per6", align: "right" },
        { text: "Multa", sortable: true, value: "mul2", align: "right" }
    ];
    tableTot1 = [];
    tableRTot1 = [];
    tableTot2 = [];
    tableRTot2 = [];
    parc1 = [];
    parc2 = [];
    errors: ChartError[] = [];
    columns = [
        {
            text: "Elemento",
            sortable: true,
            value: "element"
        },
        {
            text: "Total",
            sortable: true,
            value: "total"
        }
    ];
    // mounted() {
    //     this.getBucketBoundDates();
    // }
    activated() {
        if (this.errors.length) {
            this.fetchData();
        }
    }
    /**
     * Methods
     */
    async fetchData() {
        this.setProgressBar(true);
        this.energy1Chart = { data: [] };
        this.period1Chart = { data: [] };
        this.element1Chart = { data: [{ values: [] }] };
        this.tableTot1 = [];
        this.tableRTot1 = [];
        this.parc1 = [];
        this.energy2Chart = { data: [] };
        this.period2Chart = { data: [] };
        this.element2Chart = { data: [{ values: [] }] };
        this.tableTot2 = [];
        this.tableRTot2 = [];
        this.parc2 = [];
        this.errors = [];

        if (this.filterForm.start && this.filterForm.end) {
            if (this.filterForm.comparative && this.filterForm.days) {
                this.end1 = new Date(
                    new Date(this.filterForm.start).setDate(
                        new Date(this.filterForm.start).getDate() +
                            Number(this.filterForm.days)
                    )
                )
                    .toISOString()
                    .split("T")[0];
                this.end2 = new Date(
                    new Date(this.filterForm.end).setDate(
                        new Date(this.filterForm.end).getDate() +
                            Number(this.filterForm.days)
                    )
                )
                    .toISOString()
                    .split("T")[0];
                this.start1 = new Date(this.filterForm.start)
                    .toISOString()
                    .split("T")[0];
                this.start2 = new Date(this.filterForm.end)
                    .toISOString()
                    .split("T")[0];
                this.end = this.end2;
                this.start = this.start2;
            } else {
                this.start = this.filterForm.start;
                this.end = this.filterForm.end;
            }
        }

        do {
            let time = this.filterForm.periodType;
            if (this.filterForm.comparative) {
                time = "weekly";
            }
            const energyReq = await this.$api
                .getConsDia<responseChart>(
                    this.filterForm.installation.split(" - ")[0],
                    this.start,
                    this.end,
                    time
                )
                .then(response => {
                    return response;
                })
                .catch(error => {
                    return error;
                });
            const promises = [energyReq];
            Promise.allSettled(promises).then(async results => {
                await sleep(500);
                const energyRes = results[0];
                if (energyRes.status === "fulfilled") {
                    if (energyRes.value.ok) {
                        if (
                            this.filterForm.comparative &&
                            this.first == false
                        ) {
                            this.energy2Chart = JSON.parse(
                                energyRes.value.data.fig
                            );
                            this.period2Chart = energyRes.value.data.figPers;
                            this.columns =
                                energyRes.value.data.tableTot["columns"];
                            this.tableTot2 =
                                energyRes.value.data.tableTot["values"];
                            this.columnsRTot =
                                energyRes.value.data.tableRTot["columns"];
                            this.tableRTot2 =
                                energyRes.value.data.tableRTot["values"];
                            this.element2Chart = energyRes.value.data.figElem;
                            this.parc2 = energyRes.value.data.parc;
                        } else {
                            this.energy1Chart = JSON.parse(
                                energyRes.value.data.fig
                            );
                            this.period1Chart = energyRes.value.data.figPers;
                            this.columns =
                                energyRes.value.data.tableTot["columns"];
                            this.tableTot1 =
                                energyRes.value.data.tableTot["values"];
                            this.columnsRTot =
                                energyRes.value.data.tableRTot["columns"];
                            this.tableRTot1 =
                                energyRes.value.data.tableRTot["values"];
                            this.element1Chart = energyRes.value.data.figElem;
                            this.parc1 = energyRes.value.data.parc;
                        }
                    } else {
                        this.errors.push({
                            chart: "Consdia-energy1",
                            message: energyRes.value.message
                        });
                        this.showError(energyRes.value.message);
                    }
                }
            });
            if (this.filterForm.comparative && !this.first) {
                this.start = this.start1;
                this.end = this.end1;
                this.first = true;
            } else {
                this.first = false;
            }
        } while (this.first);
        this.setProgressBar(false);
    }

    onFilterUpdated() {
        if (Vue.$cookies.isKey(TOKEN)) {
            this.fetchData();
        } else {
            this.$router.push("/signin");
        }
    }

    formatDates() {
        if (this.filterForm?.start && this.filterForm?.end) {
            return (
                new Date(this.filterForm?.start).toDateString() +
                " - " +
                new Date(this.filterForm.end).toDateString()
            );
        } else {
            return "";
        }
    }

    checkChartErrors(chart: string) {
        const error = this.errors.filter(error => error.chart === chart);
        if (error.length && error[0].chart === chart) {
            this.showError(error[0].message);
        }
    }

    showError(error: string) {
        this.setInfoMessage({ shown: true, text: error });
    }
}
